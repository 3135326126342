<template>
    <div id="channel-board" class="page">
      <b-card class="mb-3">
        <b-row v-if="channel">
          <b-col>
            <h5 class="m-0">{{channel.name}}</h5>
            <span class="text-dark">{{channelRT.clients.online}} visitor(s)</span>
          </b-col>
          <b-col class="text-right">
            <b-link :to="{name: 'callcenter.channel', params: {channelId: channel.id}}" class="text-muted"><BIconGear font-scale="1.5"/></b-link>
          </b-col>
        </b-row>
      </b-card>
      <ClientsOnline/>
      <!--<TodoCard/>-->
      <ChannelCalls v-if="channel" title="Channel Calls" handler="channelCalls" showPagination="true" :loggedUser="user" :channelId="channel.id"/>
    </div>
</template>

<script>
import ClientsOnline from '@/modules/callcenter/components/Clients.Online';
//import TodoCard from '@/modules/crm/components/Calendar/Todo.Card';
import ChannelCalls from '@/modules/callcenter/components/Calls/Calls.Card.Template';
import {BIconGear} from 'bootstrap-vue';
export default {
    props: ['user'],
    components: {
      ClientsOnline,
      ChannelCalls,
      //TodoCard,
      BIconGear
    },
    data(){
        return {
          channel: null,
        };
    },

    methods:{
      getChannel(){
        this.$api.get(`channel/${this.$route.params.channelId}`).then(res => {
          this.channel = res.data;
        }).catch(() => {console.log('cannot fetch channel')});
      }
    },

    mounted(){
      this.getChannel();
      this.$emit('set-sidebar',{active: null});
    },

    computed: {
      channelRT(){
        return this.$store.getters['Callcenter/getChannels'].find(o => o.id === this.channel.id);
      },
    }
};
</script>

<template>
    <div id="clients-online">
      <b-row>
          <b-col class="mb-3" lg="6" md="12" sm="12">
            <b-card no-body class="card-custom">
              <template #header>
                <h6 class="mb-0">Calling</h6>
              </template>
              <b-list-group v-if="calling && calling.length > 0" flush class="clients-online-calls">
                  <b-list-group-item v-for="(item, k) in calling" :key="k">
                      <div class="float-right">
                        <b-button @click="answerCall(item)" v-if="item.isCalling" variant="success" class="rounded-circle btn-phone-rounded">
                            <BIconTelephoneFill font-scale="1.5"/>
                        </b-button>
                      </div>
                      <strong>{{ item.id }}</strong>
                      <div class="small">{{formatDate(item.isCalling.since)}}</div>
                      <div class="small">Channel: {{ getChannelName(item.isCalling.room) }}</div>
                  </b-list-group-item>
              </b-list-group>
              <div v-else class="p-3 font-italic clients-online-calls-empty">no incoming calls</div>
            </b-card>
          </b-col>
          <b-col class="mb-3" lg="6" md="12" sm="12">
            <b-card no-body class="card-custom">
              <template #header>
                <h6 class="mb-0">In a call</h6>
              </template>
              <b-list-group v-if="inCall && inCall.length > 0" flush class="clients-online-calls">
                  <b-list-group-item v-for="(item, k) in inCall" :key="k">
                      <strong>{{ item.id }}</strong>
                      <div class="small">Agent: <strong>{{ getUserName(item.isInCall.withUser) }}</strong></div>
                      <div class="small">Since: {{formatDate(item.isInCall.since)}}</div>
                      <div class="small">Channel: {{ getChannelName(item.isInCall.room) }}</div>
                      <div class="agent-ongoing-elapsed">{{ item.isInCall.timeElapsed }}</div>
                  </b-list-group-item>
              </b-list-group>
              <div v-else class="p-3 font-italic clients-online-calls-empty">no active calls</div>
            </b-card>
          </b-col>
      </b-row>
    </div>
</template>

<script>
import moment from 'moment';
import {BIconTelephoneFill} from 'bootstrap-vue';
export default {
    props: [],
    components: {
      BIconTelephoneFill
    },
    data(){
      return {
        //inCall: []
      }
    },
    methods: {
      formatDate(ts){
        return moment.unix(ts / 1000).format('HH:mm:ss DD/MM/YYYY');
      },

      answerCall(client){
        this.$callstr.signal.emit('call-picked-wait',{to: client.socketId});

        this.$store.dispatch('Callcenter/addActiveCall',{
          client: client,
          type: "answer"
        });
      },

      getChannelName(id){
        let channel = this.channels.find(o => o.id === id);
        if(!channel){
          return id;
        }
        return channel.name;
      },

      getUserName(agentId){
        let agent = this.users.find(o => o.id === agentId);
        if(agent){
          let name = [];
          if(agent.firstName){
            name.push(agent.firstName);
          }
          if(agent.lastName){
            name.push(agent.lastName);
          }

          if(name.length === 0){
            name.push(agent.email);
          }

          return name.join(' ');
        }
        return null;
      }
    },
    computed: {
      channels(){
        return this.$store.getters['Callcenter/getChannels'];
      },

      calling(){
        return this.$store.getters['Callcenter/getCalling'];
      },

      inCall(){
        return this.$store.getters['Callcenter/getInCall'];
      },

      users(){
        return this.$store.getters['Callcenter/getUsers'];
      }
    },
    /*
    watch: {
      getInCall: {
        deep: true,
        handler(data){
          this.inCall = data;
        }
      }
    }
    */
};
</script>
